import type React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAuth } from './context/AuthContext'
import './styles/style.css'
import { useNavigate } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useEffect, useState } from 'react'
import Header from './layout/header'
import Sidebar from './layout/sidebar'
import CenterLoading from './routes/components/Loading/Password'

const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { survey: survey_code, company: company_code } = useParams<{
    survey: string
    company: string
  }>()
  const { user, company, isAdmin, isLoading } = useAuth()
  const [mainNode, setMainNode] = useState<React.ReactNode>(<CenterLoading />)

  Sentry.setUser({
    email: user?.email,
  })
  Sentry.setContext('extra_info', {
    isAdmin: isAdmin,
  })

  // ログイン（システム管理者・企業担当者）
  // ヘッダーとサイドバーを表示しない
  const pageLogin =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/admin/login') ||
    location.pathname.startsWith('/set_initial_password') ||
    location.pathname.startsWith('/password/reset_url') ||
    location.pathname.startsWith('/forgot_password') ||
    location.pathname.startsWith('/reset_password') ||
    location.pathname.startsWith('/admin/forgot_password') ||
    location.pathname.startsWith('/admin/reset_password') ||
    location.pathname.startsWith('/expired_token')

  const pageAdmin = location.pathname === '/admin/dashboard' || location.pathname === '/companies'

  const pageSurveyAnswer = location.pathname.startsWith('/q/')

  const pageSurveyReport = location.pathname.startsWith(`/companies/${company_code}/surveys/${survey_code}/report`)

  useEffect(() => {
    if (isLoading) {
      setMainNode(<CenterLoading />)
      return
    }
    if (pageLogin) {
      if (user && company && !isAdmin) {
        navigate(`/companies/${company.code}/dashboard`)
        return
      }
      if (isAdmin) {
        navigate('/admin/dashboard')
        return
      }
    }
    if (pageAdmin) {
      if (user && company && !isAdmin) {
        navigate(`/companies/${company.code}/dashboard`)
        return
      }
    }
    setMainNode(
      <>
        {!(pageLogin || pageSurveyAnswer || pageSurveyReport) && (
          <>
            <Header />
            <Sidebar />
          </>
        )}
        {children}
      </>,
    )
  }, [user, company, isAdmin, isLoading, pageLogin, pageAdmin, navigate, children, pageSurveyAnswer, pageSurveyReport])

  return <>{mainNode}</>
}
export default AuthContainer
