import type React from 'react'
import '~/styles/style.css'

import { Links, Meta, Scripts } from '@remix-run/react'
import type { FallbackProps } from 'react-error-boundary'

function ErrorFallback({ error }: FallbackProps) {
  return (
    <>
      <html lang='ja'>
        <head>
          <meta charSet='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <Meta />
          <Links />
          <script
            src='https://kit.fontawesome.com/72469cc36c.js'
            crossOrigin='anonymous'
          />
        </head>
        <body className='l-management-screen h-screen bg-knowledge-navy-5'>
          <div className='h-screen w-screen flex items-center justify-center'>
            <div className='bg-white p-8 rounded-xl shadow-lg max-w-md w-full'>
              <div className='text-center'>
                <h1 className='text-2xl font-semibold mb-2'>
                  予期せぬエラーが発生しました
                </h1>
                <p className='text-gray-2 mb-6'>
                  エラーが発生した為、ページを表示できませんでした。
                  インターネット接続を確認し、再度お試しください。
                </p>
              </div>
              <button
                className='w-full bg-knowledge-navy-1 text-white font-semibold py-3 rounded-lg'
                type='button'
                onClick={() => window.location.reload()}
              >
                再読み込み
              </button>
            </div>
          </div>
          <Scripts />
        </body>
      </html>
    </>
  )
}
export default ErrorFallback
