import type { Agent } from '@newrelic/browser-agent/loaders/agent'

// Populate using values in copy-paste JavaScript snippet.
const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  }, // NREUM.init
  info: {
    beacon: 'bam.nr-data.net', // 固定
    errorBeacon: 'bam.nr-data.net', // 固定
    licenseKey: 'NRJS-e3d9f8ae2e6037ba22c',
    applicationID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
    sa: 1, // 固定
  }, // NREUM.info
  loader_config: {
    accountID: '4480684',
    trustKey: '4480684',
    agentID: import.meta.env.VITE_NEW_RELIC_AGENT_ID,
    licenseKey: 'NRJS-e3d9f8ae2e6037ba22c',
    applicationID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
  }, // NREUM.loader_config
}

let browserAgent: Agent | null = null

export async function initNewRelic() {
  if (!browserAgent) {
    try {
      const newRelicType = await import('@newrelic/browser-agent/loaders/agent')

      // Import the feature you want to use
      const { JSErrors } = await import(
        '@newrelic/browser-agent/features/jserrors'
      )
      browserAgent = new newRelicType.Agent({
        ...options,
        features: [JSErrors],
      })
    } catch (error) {
      console.error('Failed to initialize New Relic:', error)
    }
  }
}

export function noticeNewRelicError(error: Error) {
  if (browserAgent) {
    browserAgent.noticeError(error)
  } else {
    console.warn('New Relic Browser Agent is not initialized')
  }
}
