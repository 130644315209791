import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'

const LogContext = createContext({})
const isLogging = import.meta.env.VITE_IS_LOGGING

export const LogProvider = ({ children }: { children: ReactNode }) => {
  /**
   * Action用のログを記録
   * @param {string} action - アクション名
   * @param {Object} details - 詳細情報
   *
   * @example コンポーネント内での使用例
   * const { logAction } = useLog();
   *
   * const handleClick = () => {
   *  logAction('Button clicked', { button: 'exampleButton' });
   * };
   */
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  const logAction = useCallback((action: string, details: Object) => {
    if (isLogging === 'true') {
      console.log(`Action: ${action}`, details)
    }
  }, [])

  const location = useLocation()

  // 遷移したURLをログに記録
  useEffect(() => {
    const pathname = location.pathname
    logAction('Page loaded', { pathname: pathname })
  }, [location, logAction])

  const logPerformance = useCallback(() => {
    const [navigationEntry] = performance.getEntriesByType('navigation')
    if (navigationEntry) {
      const loadTime = navigationEntry.duration
      logAction('Page Load Time', { loadTime: loadTime })
    }
  }, [logAction])

  useEffect(() => {
    logPerformance()
  }, [logPerformance])

  return (
    <LogContext.Provider value={{ logAction }}>{children}</LogContext.Provider>
  )
}

export const useLog = () => useContext(LogContext)
