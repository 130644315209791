import { useEffect, useRef, useState } from 'react'

function usePopupVisibility(initialId: string | null = null) {
  // ポップアップの表示状態を管理するstate
  const [visiblePopupId, setVisiblePopupId] = useState<string | null>(initialId)
  const timerRef = useRef<number | null>(null)

  // マウスがポップアップに入ったときの処理
  const handleMouseEnter = (id: string) => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }
    setVisiblePopupId(id)
  }

  // マウスがポップアップから離れたときの処理
  const handleMouseLeave = () => {
    timerRef.current = window.setTimeout(() => {
      setVisiblePopupId(null)
    }, 500)
  }

  // クリーンアップ（タイマーが残らないようにする）
  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  // 必要なデータや関数を返す
  return { visiblePopupId, handleMouseEnter, handleMouseLeave }
}

export default usePopupVisibility
